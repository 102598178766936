import { UserContextValue } from "domains/user/contexts/UserProvider";

export function calculateAbTestId(user: NonNullable<UserContextValue["user"]>) {
  if (!user.primaryEmailAddress?.emailAddress) {
    return;
  }
  const abTestHash = user.primaryEmailAddress.emailAddress
    .split("@")[0]
    .split("")
    .map((str) => str.charCodeAt(0))
    .reduce((memo, num) => memo + num, 0);

  if (abTestHash && abTestHash % 2 === 0) return "A";
  if (abTestHash && abTestHash % 2 === 1) return "B";
}
