import { startCase } from "lodash";

import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";
import {
  AnyAction,
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";

const ENABLE_ALL_TOASTS =
  process.env.NODE_ENV === "development" &&
  !process.env.NEXT_PUBLIC_DISABLE_DEV_TOAST;
type ToastType = "mutation" | "query" | "success" | "error";
const SHOW_TOAST_FOR: ToastType[] = ["mutation", "query", "error", "success"];

export const { toast } = createStandaloneToast();

const isMutation = (action: AnyAction) =>
  action?.meta?.arg?.type === "mutation";
const shouldShowToast = (action: AnyAction) => {
  if (isMutation(action)) {
    return SHOW_TOAST_FOR.includes("mutation");
  }
  if (isRejectedWithValue()(action)) {
    return SHOW_TOAST_FOR.includes("error");
  }
  if (isPending()(action)) {
    return SHOW_TOAST_FOR.includes("query");
  }
  if (isFulfilled()(action)) {
    return SHOW_TOAST_FOR.includes("success");
  }

  return false;
};

/** Middleware for rtk query to intercept all queries and display a toast if wanted */
export const queryStatusToastMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    if (!ENABLE_ALL_TOASTS) {
      return next(action);
    }
    const shouldShow = shouldShowToast(action);

    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (!isAsyncThunkAction(action)) {
      return next(action);
    }

    let toastOptions: UseToastOptions = {
      id: action.meta.requestId,
      title: startCase((action.meta?.arg as any)?.endpointName || "Request"),
      position: "bottom-right",
      isClosable: true,
      variant: "left-accent",
      duration: 4_000,
    };

    if (isPending()(action) && shouldShow) {
      if (!toast.isActive(action.meta.requestId)) {
        toast({ ...toastOptions, description: "Loading...", status: "info" });
      } else {
        toast.update(action.meta.requestId, { ...toastOptions });
      }
    }

    if (isRejectedWithValue()(action) && shouldShow) {
      toastOptions = {
        ...toastOptions,
        description: (action.payload as any).data?.reason,
        status: "error",
      };
      if (!toast.isActive(action.meta.requestId)) {
        toast(toastOptions);
      } else {
        toast.update(action.meta.requestId, toastOptions);
      }
    }

    if (isFulfilled()(action) && shouldShow) {
      toastOptions = {
        ...toastOptions,
        description: "Success",
        status: "success",
      };
      if (!toast.isActive(action.meta.requestId)) {
        toast(toastOptions);
      } else {
        toast.update(action.meta.requestId, toastOptions);
      }
    }

    return next(action);
  };
