import * as Sentry from "@sentry/react";

/**
 * Assert that a variable is not gonna be used, this is only for type-checking the exhaustiveness of switch for example
 * ```typescript
 *   switch (s.kind) {
 *      case "square": return s.size * s.size;
 *      case "rectangle": return s.height * s.width;
 *      case "circle": return Math.PI * s.radius ** 2;
 *      default: return assertNever(s); // error here if there are missing cases
 *  }
 * ```
 *  @see [Exhaustiveness checking](https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking)
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function assertNever(
  x: never,
  message = `assertNever unexpected object: ${x as string}`
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): never {
  // eslint-disable-next-line no-console
  console.error(message);
  Sentry.captureException(new Error(message));
}
