import React from "react";

import { Flex, Spinner } from "@chakra-ui/react";

export default React.memo(function Loading() {
  return (
    <Flex align="center" justify={"center"} w="100%" h="100%">
      <Spinner size="lg" />
    </Flex>
  );
});
