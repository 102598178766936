import { Text, TextProps } from "@chakra-ui/react";

interface MenuTitleProps {
  text: TextProps["children"];
  variant?: "blurred" | "primary";
}

export default function MenuTitle({
  text,
  variant = "primary",
}: MenuTitleProps) {
  return (
    <Text
      mt={variant === "blurred" ? 0 : 1}
      mb={variant === "blurred" ? 0 : 2}
      mx={variant === "blurred" ? 2.5 : 1.5}
      pt={variant === "blurred" ? 1 : 0}
      pb={variant === "blurred" ? 1 : 0}
      color={variant === "blurred" ? "textSecondary" : "textTertiary"}
      textAlign="left"
      size={variant === "blurred" ? "body.bold.sm" : "body.sm"}
    >
      {text}
    </Text>
  );
}
