import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import {
  NOTIFICATION_TYPES,
  NotificationType,
} from "domains/user/constants/Notifications";
import {
  DEPRECATED_NSFW_TYPES,
  NSFW_TYPES,
  NsfwType,
} from "domains/user/constants/Nsfw";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  GetMeApiResponse,
  useGetMeQuery,
  usePutMeMutation,
} from "infra/api/generated/api";
import _ from "lodash";

import { useUser as useClerkUser } from "@clerk/nextjs";
import { UserResource } from "@clerk/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export type UserContextValue = {
  user:
    | ({
        id: string | undefined;
        avatar: { label: string | undefined };
        email: string | undefined;
      } & Omit<UserResource, "avatar" | "id">)
    | undefined;
  userSettings: { [key: string]: any } | undefined;
  updateUserSettings: (newSettings: { [key: string]: any }) => void;
  featureFlags: string[];

  nsfwRevealedAssetIds: string[];
  nsfwFilteredTypes: NsfwType[];
  setNsfwFilter: (values: NsfwType[]) => void;
  revealAsset: (assetId: string) => void;

  notificationSettings: NotificationType[];
  setNotificationSettings: (settings: NotificationType[]) => void;

  policy: string | undefined;
  lastAcceptedPolicy: string | undefined;
  markPolicyAsRead: () => void;
  isPolicyAcceptanceMissing: boolean;

  submitOnboardingForm: (form: OnboardingForm) => void;
  isOnboardingFormMissing: boolean;
};

export const UserContext = createContext<UserContextValue>({
  user: undefined,
  userSettings: undefined,
  updateUserSettings: () => {},
  featureFlags: [],

  nsfwRevealedAssetIds: [],
  nsfwFilteredTypes: [],
  setNsfwFilter: () => {},
  revealAsset: () => {},

  notificationSettings: [],
  setNotificationSettings: () => {},

  policy: undefined,
  lastAcceptedPolicy: undefined,
  markPolicyAsRead: () => {},
  isPolicyAcceptanceMissing: false,

  submitOnboardingForm: () => {},
  isOnboardingFormMissing: false,
});

// ------------------------------------

export function UserProvider({ children }: PropsWithChildren) {
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const [putMeTrigger] = usePutMeMutation();

  const { user: clerkUser } = useClerkUser();

  const { user } = useGetMeQuery(
    clerkUser
      ? ({
          userId: clerkUser.id,
        } as any)
      : skipToken,
    {
      selectFromResult: ({ data }) => {
        return {
          user: data?.user,
        };
      },
    }
  );
  const userData = useMemo(
    () =>
      clerkUser
        ? {
            ...clerkUser,
            id: user?.id,
            avatar: {
              label: clerkUser.primaryEmailAddress?.emailAddress,
            },
            email: clerkUser.primaryEmailAddress?.emailAddress,
          }
        : undefined,
    [clerkUser, user]
  );

  const featureFlags = useMemo(() => user?.features ?? [], [user?.features]);
  const settings = useMemo(
    ():
      | {
          [key: string]: any;
        }
      | undefined => user?.settings,
    [user?.settings]
  );

  const updateUserSettings = useCallback(
    async (newSettings: { [key: string]: any }) => {
      if (!user) {
        throw new Error();
      }
      await putMeTrigger({
        body: {
          settings: {
            ...settings,
            ...newSettings,
          },
        },
      }).unwrap();
    },
    [putMeTrigger, settings, user]
  );

  const [nsfwRevealedAssetIds, setNsfwRevealedAssetIds] = useState<string[]>(
    []
  );
  const nsfwFilteredTypes = useMemo<NsfwType[]>(() => {
    if (settings && typeof settings["nsfw-filter"] === "string") {
      const savedTypes = settings["nsfw-filter"].split(",");

      const addRelatedTypeIfMissing = (typeA: string, typeB: string) => {
        if (savedTypes.includes(typeA) && !savedTypes.includes(typeB)) {
          savedTypes.push(typeB);
        }
        if (savedTypes.includes(typeB) && !savedTypes.includes(typeA)) {
          savedTypes.push(typeA);
        }
      };
      addRelatedTypeIfMissing("explicit_nudity", "explicit");
      addRelatedTypeIfMissing("suggestive", "non_explicit");

      return NSFW_TYPES.filter((value) => savedTypes.includes(value));
    } else {
      return ["explicit_nudity", "explicit"];
    }
  }, [settings]);
  const revealAsset = useCallback(
    (assetId: string) =>
      setNsfwRevealedAssetIds((assetIds) => [...assetIds, assetId]),
    [setNsfwRevealedAssetIds]
  );

  const setNsfwFilter = useCallback(
    async (values: NsfwType[]) => {
      try {
        await updateUserSettings({
          "nsfw-filter": values
            .filter((item) => !DEPRECATED_NSFW_TYPES.includes(item))
            .join(","),
        });
      } catch (error) {
        handleApiError(
          error,
          "There was an error saving your safety filter preference"
        );
      }
    },
    [handleApiError, updateUserSettings]
  );

  const notificationSettings = useMemo(() => {
    const keys = Object.keys(user?.notifications ?? {}) as NotificationType[];
    return keys.reduce((acc, key) => {
      if (
        user?.notifications?.[key]?.email ||
        user?.notifications?.[key]?.mobile
      ) {
        acc.push(key as NotificationType);
      }
      return acc;
    }, [] as NotificationType[]);
  }, [user]);
  const setNotificationSettings = useCallback(
    async (newNotificationSettings: NotificationType[]) => {
      try {
        await putMeTrigger({
          body: {
            notifications: {
              ...NOTIFICATION_TYPES.reduce((acc, key) => {
                acc[key] = {
                  email: newNotificationSettings.includes(key),
                  mobile: newNotificationSettings.includes(key),
                };
                return acc;
              }, {} as NonNullable<GetMeApiResponse["user"]["notifications"]>),
            },
          },
        });
      } catch (error) {
        handleApiError(
          error,
          "There was an error saving your notification settings"
        );
      }
    },
    [handleApiError, putMeTrigger]
  );

  const policy = featureFlags
    .find((flag) => flag.match(/^policy:.+$/))
    ?.replace(/^policy:/, "");
  const lastAcceptedPolicy =
    policy && settings && (settings["last-policy-accepted"] as string);
  const isPolicyAcceptanceMissing = !!(policy && policy !== lastAcceptedPolicy);
  const markPolicyAsRead = useCallback(async () => {
    try {
      await updateUserSettings({
        "last-policy-accepted": policy,
      });
      Track(AnalyticsEvents.User.PolicyAccepted, { policy });
    } catch (error) {
      handleApiError(error, "Error saving policy opt-in");
    }
  }, [handleApiError, policy, updateUserSettings]);

  const isOnboardingFormMissing = !!(settings && !settings["onboarded"]);
  const submitOnboardingForm = useCallback(
    async (form: OnboardingForm) => {
      try {
        await putMeTrigger({
          body: {
            fullName: form.fullName,

            ...(() => {
              if (form.phoneNumber && form.countryCode) {
                return {
                  phoneNumber: form.phoneNumber,
                  countryCode: form.countryCode.toUpperCase(),
                };
              } else {
                return {};
              }
            })(),
          },
        }).unwrap();

        await updateUserSettings({
          onboarded: true,
          "company-name": form.companyName,
          role: form.role,
          "traffic-source": form.source,
          "platform-usage": form.usage,
          contact: form.contact,
          "last-policy-accepted": policy,
        });

        successToast({ title: "Profile completed" });

        Track(AnalyticsEvents.User.PolicyAccepted, { policy });
        Track(
          AnalyticsEvents.User.Onboarded,
          _.omit(form, ["fullName", "companyName", "phoneNumber"])
        );
      } catch (error) {
        handleApiError(error, "Error submitting form");
      }
    },
    [successToast, handleApiError, putMeTrigger, updateUserSettings, policy]
  );

  // ----------------------------------

  const contextValue = useMemo(
    () => ({
      user: userData,
      userSettings: settings,
      updateUserSettings,
      featureFlags,

      nsfwRevealedAssetIds,
      setNsfwRevealedAssetIds,
      nsfwFilteredTypes,
      setNsfwFilter,
      revealAsset,

      notificationSettings,
      setNotificationSettings,

      policy,
      lastAcceptedPolicy,
      markPolicyAsRead,
      isPolicyAcceptanceMissing,

      submitOnboardingForm,
      isOnboardingFormMissing,
    }),
    [
      featureFlags,
      isOnboardingFormMissing,
      isPolicyAcceptanceMissing,
      lastAcceptedPolicy,
      markPolicyAsRead,
      notificationSettings,
      nsfwFilteredTypes,
      nsfwRevealedAssetIds,
      policy,
      revealAsset,
      setNotificationSettings,
      setNsfwFilter,
      settings,
      submitOnboardingForm,
      updateUserSettings,
      userData,
    ]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext<UserContextValue>(UserContext);
}
