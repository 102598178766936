import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/router";
import { BackgroundTask } from "domains/background-tasks/interfaces/BackgroundTask";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { ButtonProps } from "domains/ui/components/Button/index";
import { useUserContext } from "domains/user/contexts/UserProvider";
import { GetJobsApiResponse, useGetJobsQuery } from "infra/api/generated/api";
import _ from "lodash";
import moment from "moment";

import { skipToken } from "@reduxjs/toolkit/query";

interface BackgroundTaskContextType {
  open: () => void;
  close: () => void;
  clearTask: (id: string) => void;
  refresh: () => void;
  loadMore: () => void;
  recentTasks: BackgroundTask[];
  tasks: BackgroundTask[];
  isOpen: boolean;
  isLoading: boolean;
  isRefreshing: boolean;
  hasMore: boolean;
  pause: (pauseId: string, ids: string[]) => void;
  clearPause: (pauseId: string) => void;
}

export const BackgroundTaskContext = createContext<BackgroundTaskContextType>({
  open: () => {},
  close: () => {},
  clearTask: () => {},
  refresh: () => {},
  loadMore: () => {},
  recentTasks: [],
  tasks: [],
  isOpen: false,
  isLoading: false,
  isRefreshing: false,
  hasMore: false,
  pause: () => {},
  clearPause: () => {},
});

// ------------------------------------

interface BackgroundTaskProviderProps {
  children?: React.ReactNode;
}

export function BackgroundTaskProvider({
  children,
}: BackgroundTaskProviderProps) {
  const { user, updateUserSettings, userSettings } = useUserContext();
  const { selectedTeam } = useTeamContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [paginationToken, setPaginationToken] = useState<string | undefined>();
  const [pauses, setPauses] = useState<{ [key: string]: string[] }>({});

  const pausedTaskIds = useMemo(() => _.flatten(_.values(pauses)), [pauses]);
  const clearedTaskIds = useMemo(
    () => (_.get(userSettings, "cleared-tasks") ?? []) as string[],
    [userSettings]
  );

  const { data: jobsData, refetch: refresh } = useGetJobsQuery(
    user?.id
      ? {
          teamId: selectedTeam.id,
          pageSize: "100",
          authorId: user.id,
        }
      : skipToken
  );

  const router = useRouter();

  const isOnCanvasIdPage = useMemo(
    () => router.pathname.startsWith("/canvas/"),
    [router.pathname]
  );

  useGetJobsQuery(
    paginationToken && user?.id
      ? {
          teamId: selectedTeam.id,
          pageSize: "100",
          paginationToken,
          authorId: user.id,
        }
      : skipToken || isOnCanvasIdPage
  );

  const filteredJobs = useMemo(
    () => jobsData?.jobs.filter((job) => job.status !== "canceled") ?? [],
    [jobsData]
  );

  const tasks = useMemo(() => {
    return filteredJobs
      .map((job) => {
        const type =
          (job.jobType === "inference" &&
            _.get(job, "metadata.input.type", "").includes("texture") &&
            "texture") ||
          (job.jobType === "flux" && "inference") ||
          (job.jobType === "texture" && "textureMaps") ||
          (job.jobType === "flux-model-training" && "model-training") ||
          job.jobType;

        return {
          id: `job-${job.jobId}`,
          date: job.createdAt,
          type: getType(type),
          label: getLabel(type, job),
          status: getStatus(job),
          ...getLinkProps(type, job),
          progress: job.progress ? job.progress * 100 : undefined,
        };
      })
      .filter((job) => job.type !== undefined) as BackgroundTask[];
  }, [filteredJobs]);

  const recentTasks = useMemo(
    () =>
      tasks
        .filter(
          (task) =>
            !clearedTaskIds.includes(task.id) &&
            moment.utc().diff(moment.utc(task.date), "minutes") < 4 * 60
        )
        .slice(0, 8),
    [tasks, clearedTaskIds]
  );

  const hasMore = !!jobsData?.nextPaginationToken;
  const isLoading = useMemo(
    () => !!recentTasks.find((task) => task.status === "loading"),
    [recentTasks]
  );

  // ----------------------------------

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const clearTask = useCallback(
    async (id: string) => {
      const taskIds = tasks.map((task) => task.id);
      const newClearedTasks = [
        ...clearedTaskIds.filter((id) => taskIds.includes(id)),
        id,
      ];

      await updateUserSettings({
        "cleared-tasks": newClearedTasks,
      });
    },
    [updateUserSettings, clearedTaskIds, tasks]
  );

  const loadMore = useCallback(() => {
    if (jobsData?.nextPaginationToken) {
      setPaginationToken(jobsData.nextPaginationToken);
    }
  }, [jobsData?.nextPaginationToken]);

  const pause = useCallback(
    (pauseId: string, taskIds: string[]) => {
      setPauses((pauses) => ({ ...pauses, [pauseId]: taskIds }));
    },
    [setPauses]
  );

  const clearPause = useCallback(
    (pauseId: string) => setPauses((pauses) => _.omit(pauses, [pauseId])),
    [setPauses]
  );

  // ----------------------------------

  useEffect(() => {
    const isRefreshing =
      filteredJobs.findIndex(
        (job) =>
          ["in-progress", "warming-up", "queued"].includes(job.status) &&
          !pausedTaskIds.includes(job.jobId)
      ) >= 0;
    setIsRefreshing(isRefreshing);
  }, [pausedTaskIds, filteredJobs, setIsRefreshing]);

  // This useEffect cannot be replaced by pollingInterval due to a bug that transfer
  // the paginationToken to all same queries (see the 2 same useGetJobsQuery hooks)
  useEffect(() => {
    if (!isRefreshing || isOnCanvasIdPage) return;
    const interval = setInterval(refresh, 5_000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isOnCanvasIdPage, isRefreshing, refresh]);

  // ----------------------------------

  const backgroundTaskContextValue = useMemo(
    () => ({
      open,
      close,
      clearTask,
      refresh,
      loadMore,
      tasks,
      recentTasks,
      isOpen,
      isLoading,
      isRefreshing,
      hasMore,
      pause,
      clearPause,
    }),
    [
      open,
      close,
      clearTask,
      refresh,
      loadMore,
      tasks,
      recentTasks,
      isOpen,
      isLoading,
      isRefreshing,
      hasMore,
      pause,
      clearPause,
    ]
  );

  return (
    <BackgroundTaskContext.Provider value={backgroundTaskContextValue}>
      {children}
    </BackgroundTaskContext.Provider>
  );
}

export function useBackgroundTaskContext() {
  return useContext<BackgroundTaskContextType>(BackgroundTaskContext);
}

// ------------------------------------

function getType(type: string): BackgroundTask["type"] | undefined {
  return (
    (
      {
        inference: "inference",
        upscale: "upscale",
        pixelate: "pixelate",
        vectorize: "vectorize",
        "remove-background": "bgRemove",
        restyle: "restyle",
        "skybox-base-360": "skybox",
        "skybox-upscale-360": "skybox",
        texture: "texture",
        textureMaps: "texture",
        "upscale-texture": "texture",
        "model-training": "modelTraining",
        "model-import": "modelUpload",
        "assets-download": "assetsDownload",
        "model-download": "modelDownload",
        reframe: "reframe",
      } as const
    )[type] ?? undefined
  );
}

function getLabel(
  type: string,
  job: GetJobsApiResponse["jobs"][number]
): BackgroundTask["label"] | undefined {
  return (
    {
      "model-training": "Model Training",
      "model-import": "Model Upload",
      "model-download": "Model Download",
      inference: _.compact([
        "Image",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      upscale: _.compact([
        "Enhance",
        _.get(job?.metadata.input, "scalingFactor")
          ? `${_.get(job?.metadata.input, "scalingFactor")}x`
          : undefined,
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      vectorize: "Vectorization",
      pixelate: "Pixelate",
      "remove-background": "Remove Background",
      restyle: _.compact([
        "Restyle",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      "skybox-base-360": _.compact([
        "Skybox",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      "skybox-upscale-360": _.compact([
        "Skybox Enhance",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      reframe: _.compact(["Expand", _.get(job?.metadata.input, "prompt")]).join(
        ", "
      ),
      texture: _.compact([
        "Texture",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      textureMaps: "Texture Maps",
      "upscale-texture": _.compact([
        "Texture Enhance",
        _.get(job?.metadata.input, "scalingFactor")
          ? `${_.get(job?.metadata.input, "scalingFactor")}x`
          : undefined,
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      "assets-download": "Assets Download",
    }[type] ?? undefined
  );
}

function getStatus(
  job: GetJobsApiResponse["jobs"][number]
): BackgroundTask["status"] | undefined {
  return (
    (
      {
        canceled: "failed",
        failure: "failed",
        success: "succeeded",
        "in-progress": "loading",
        "warming-up": "loading",
        queued: "loading",
      } as const
    )[job.status] ?? undefined
  );
}

function getLinkProps(
  type: string,
  job: GetJobsApiResponse["jobs"][number]
):
  | {
      internalLink: ButtonProps["internalLink"];
    }
  | {
      externalLink: ButtonProps["externalLink"];
    }
  | undefined {
  const isSuccessfulJob = job.status === "success";
  const hasAssets = (job.metadata.assetIds ?? []).length > 0;
  const lastAssetId = hasAssets
    ? job.metadata?.assetIds?.[job.metadata.assetIds.length - 1]
    : undefined;

  // Image-related jobs
  const imageJobTypes = [
    "inference",
    "reframe",
    "upscale",
    "expand",
    "pixelate",
    "vectorize",
    "remove-background",
    "restyle",
  ];
  if (imageJobTypes.includes(type) && isSuccessfulJob && hasAssets) {
    return {
      internalLink: {
        pathname: "/images",
        query: { openAssetId: lastAssetId },
      },
    };
  }

  // Skybox jobs
  const skyboxJobTypes = ["skybox-base-360", "skybox-upscale-360"];
  if (skyboxJobTypes.includes(type) && isSuccessfulJob && hasAssets) {
    return {
      internalLink: {
        pathname: "/skyboxes",
        query: { openAssetId: lastAssetId },
      },
    };
  }

  // Texture jobs
  const textureJobTypes = ["texture", "textureMaps", "upscale-texture"];
  if (textureJobTypes.includes(type) && isSuccessfulJob && hasAssets) {
    return {
      internalLink: {
        pathname: "/textures",
        query: { openAssetId: lastAssetId },
      },
    };
  }

  // Model jobs
  const modelId =
    "modelId" in job.metadata ? (job.metadata.modelId as string) : undefined;
  if ((type === "model-training" || type === "model-import") && modelId) {
    return {
      internalLink: {
        pathname: "/models/[id]",
        query: { id: modelId },
      },
    };
  }

  // Download jobs
  const downloadJobTypes = ["assets-download", "model-download"];
  const jobUrl = (job.metadata.output as any)?.url;
  if (downloadJobTypes.includes(type) && isSuccessfulJob && jobUrl) {
    return {
      externalLink: jobUrl,
    };
  }

  return undefined;
}
