import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import { CuIndicator } from "domains/ui/components/ButtonWithCuIndicator";
import Icon, { IconId, IconProps } from "domains/ui/components/Icon";

import {
  Box,
  Center,
  Checkbox,
  HStack,
  //eslint-disable-next-line no-restricted-imports
  MenuItem as ChakraMenuItem,
  Text,
} from "@chakra-ui/react";
import { ComponentWithAs } from "@chakra-ui/system";

export interface MenuItemProps extends ButtonProps {
  check?: "checkbox" | "icon";
  text: React.ReactNode;
  iconId?: IconId;
  iconH?: IconProps["h"];
  selectedIconId?: IconId;
  isSelected?: boolean;
  isFocused?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  dataTestId?: string;
  isHidden?: boolean;
  cuCost?: {
    cost: number | undefined;
    isLoading: boolean;
  };
}

function MenuItem({
  check,
  text,
  iconId,
  iconH,
  selectedIconId,
  isSelected,
  isFocused,
  onClick,
  dataTestId,
  isDisabled,
  isHidden,
  cuCost,
  ...menuItemProps
}: MenuItemProps) {
  const color: ButtonProps["color"] =
    ((isSelected || isFocused) && "textPrimary") ||
    (isDisabled && "textTertiary") ||
    "textSecondary";

  return (
    <ChakraMenuItem
      as={Button}
      w="100%"
      px={1.5}
      _hover={{
        bgColor: isFocused ? "whiteAlpha.300" : undefined,
      }}
      pointerEvents={isDisabled ? "none" : "auto"}
      bgColor={
        isFocused || (isSelected && isFocused === undefined)
          ? "whiteAlpha.300"
          : "transparent"
      }
      data-testid={dataTestId}
      isDisabled={isDisabled}
      onClick={!(isDisabled || isHidden) ? onClick : undefined}
      variant="menuItem"
      {...(isHidden && { display: "none", tabIndex: -1 })}
      {...menuItemProps}
      data-group
    >
      <HStack w="full" spacing={1.5}>
        {iconId && (
          <Center w="20px" h="20px">
            <Icon
              id={iconId}
              h={iconH}
              color={color}
              _groupHover={{
                color: "textPrimary",
              }}
            />
          </Center>
        )}

        {check === "checkbox" && (
          <Checkbox
            pointerEvents="none"
            colorScheme="primary"
            isChecked={isSelected}
          />
        )}

        <Text
          flex={1}
          overflow="hidden"
          color={color}
          _groupHover={{
            color: "textPrimary",
          }}
          isTruncated
          size="body.md"
        >
          {text}
        </Text>

        {cuCost && (
          <CuIndicator
            p={0}
            cuCost={cuCost.cost}
            isCuLoading={cuCost.isLoading}
          />
        )}

        {check === "icon" && (
          <Box pl={3} visibility={isSelected ? "visible" : "hidden"}>
            <Icon id={selectedIconId ?? "Ui/Check"} color="textSecondary" />
          </Box>
        )}
      </HStack>
    </ChakraMenuItem>
  );
}

export default MenuItem as ComponentWithAs<"button", MenuItemProps>;
