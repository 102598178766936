import { BackgroundTask } from "domains/background-tasks/interfaces/BackgroundTask";
import { assertNever } from "domains/commons/assertNever";
import Icon from "domains/ui/components/Icon";

interface BackgroundTaskIconProps {
  type: BackgroundTask["type"];
}

export default function BackgroundTaskIcon({ type }: BackgroundTaskIconProps) {
  const iconId = (() => {
    switch (type) {
      case "modelTraining":
      case "modelUpload":
        return "Nav/Model/Solid";
      case "modelDownload":
      case "assetsDownload":
        return "Ui/Download";
      case "inference":
        return "Nav/Image/Solid";
      case "upscale":
        return "Nav/Upscale/Solid";
      case "vectorize":
        return "Nav/Vectorize/Solid";
      case "pixelate":
        return "Nav/Pixelate/Solid";
      case "bgRemove":
        return "Nav/BgRemove/Solid";
      case "restyle":
        return "Nav/Restyle/Outline";
      case "skybox":
        return "Nav/Skybox/Solid";
      case "texture":
        return "Nav/Texture/Solid";
      case "reframe":
        return "Nav/Expand/Outline";
      default:
        return assertNever(type);
    }
  })();

  return <Icon id={iconId} h="16px" color="textSecondary" />;
}
