import { GetJobsApiResponse } from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";
import _ from "lodash";

export const jobsEndpoints: Endpoints = {
  getJobs: {
    merge(existing: GetJobsApiResponse, incoming: GetJobsApiResponse, { arg }) {
      existing.jobs = _.chain(incoming.jobs)
        .unionBy(_.cloneDeep(existing.jobs), "jobId")
        .orderBy("createdAt", "desc")
        .value();

      if (!arg.paginationToken && existing.nextPaginationToken) return;
      if (existing?.jobs && !existing.nextPaginationToken) return;

      existing.nextPaginationToken = incoming.nextPaginationToken;
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return (
        endpointName +
        queryArgs.teamId +
        queryArgs.status +
        queryArgs.type +
        queryArgs.types +
        queryArgs.authorId
      );
    },
    forceRefetch({ currentArg, previousArg }) {
      return !_.isEqual(currentArg, previousArg);
    },
    providesTags: [API_TAGS.job],
  },

  getJobId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        for (const {
          endpointName,
          originalArgs,
        } of apiSlice.util.selectInvalidatedBy(getState(), [
          API_TAGS.job,
        ]) as SelectedInvalidatedByTag[]) {
          if (endpointName === "getJobs") {
            dispatch(
              apiSlice.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  if (draft) {
                    const jobIndex = draft.jobs.findIndex(
                      (item) => item.jobId === data.job.jobId
                    );
                    if (jobIndex !== -1) {
                      draft.jobs[jobIndex] = data.job;
                    }
                  }
                }
              )
            );
          }
        }
      }
    },
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.job,
          id: `jobId:${arg.jobId}`,
        },
      ];
    },
  },
};
