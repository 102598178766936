import React from "react";
import { formatCreativeUnits } from "domains/commons/misc";
import { extraTheme } from "domains/theme";
import { sizes } from "domains/theme/components/text";
import Button, {
  ButtonGoogleTranslateFix,
  ButtonProps,
} from "domains/ui/components/Button";

import {
  Box,
  Center,
  HStack,
  Spinner,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";

interface CuIndicatorProps extends StackProps {
  cuCost?: number;
  isCuLoading: boolean;
  isTooltipDisabled?: boolean;
}

export function CuIndicator({
  cuCost,
  isCuLoading,
  isTooltipDisabled,
  ...props
}: CuIndicatorProps) {
  if (cuCost === undefined && !isCuLoading) {
    return null;
  }

  return (
    <Tooltip
      isDisabled={isTooltipDisabled}
      label={
        isCuLoading
          ? "Estimating Creative Unit Cost"
          : `Uses ${cuCost} creative ${(cuCost ?? 0) > 1 ? "units" : "unit"}`
      }
    >
      <Stack align="stretch" p={2} {...props}>
        <HStack
          pos="relative"
          h="20px"
          px={2}
          fontSize={sizes["body.sm"].fontSize}
          borderWidth={1}
          borderColor={isCuLoading ? "whiteAlpha.200" : "whiteAlpha.400"}
          borderRadius="md"
          transition={extraTheme.transitions.fast}
          bgColor="whiteAlpha.200"
          spacing={1}
        >
          {isCuLoading && (
            <Spinner
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              m="auto"
              size="xs"
              thickness="1.5px"
            />
          )}
          <Text
            opacity={isCuLoading ? 0 : 1}
            data-testid="cu-indicator-value"
            size="body.sm"
          >{`${formatCreativeUnits(cuCost ?? 0)}`}</Text>
        </HStack>
      </Stack>
    </Tooltip>
  );
}

// ------------------------------------

interface ButtonWithCuIndicatorProps extends ButtonProps {
  cuCost?: number;
  isCuLoading?: boolean;
  isInline?: boolean;
}

const ButtonWithCuIndicator = React.forwardRef(function ButtonWithCuIndicator(
  {
    cuCost,
    isCuLoading = false,
    children,
    isInline = false,
    isLoading = false,
    ...props
  }: ButtonWithCuIndicatorProps,
  ref
) {
  return (
    <Button
      ref={ref}
      pr={!isLoading ? 0 : undefined}
      pos="relative"
      isLoading={isLoading}
      {...props}
    >
      <Box flex={1}>
        <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
      </Box>
      {!isLoading && (
        <Center
          {...(!isInline
            ? { position: "absolute", right: 0, top: 0, bottom: 0 }
            : {})}
        >
          <CuIndicator pl={0} cuCost={cuCost} isCuLoading={isCuLoading} />
        </Center>
      )}
    </Button>
  );
});

export default ButtonWithCuIndicator;
