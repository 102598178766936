import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { GuideKeys, GUIDES } from "domains/guide/constants/guides";
import Button from "domains/ui/components/Button";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { compiler } from "markdown-to-jsx";
import { useHotkeys } from "react-hotkeys-hook";

import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";

interface TourGuideProviderContextValue {
  forceShowGuide: (guideKey: GuideKeys, step?: number) => void;
}

export const TourGuideContext = createContext<TourGuideProviderContextValue>({
  forceShowGuide: () => {},
});

export function TourGuideProvider({ children }: PropsWithChildren) {
  const [currentGuide, setCurrentGuide] = useState<
    | {
        guideKey: GuideKeys;
        step: number;
        isForce: boolean;
      }
    | undefined
  >();

  const closeGuide = useCallback(() => {
    if (currentGuide) {
      Track(AnalyticsEvents.TourGuide.ClosedTutorial, {
        guide: currentGuide.guideKey,
      });
      Track(AnalyticsEvents.TourGuide.ClosedPage, {
        guide: currentGuide.guideKey,
        page: currentGuide.step + 1,
        newPage: "n/a",
      });
    }
    setCurrentGuide(undefined);
  }, [currentGuide]);

  const forceShowGuide = useCallback((guideKey: GuideKeys, step?: number) => {
    Track(AnalyticsEvents.TourGuide.OpenedTutorial, {
      guide: guideKey,
      auto: false,
    });
    const correctStep =
      step === undefined || step < 1 || step > GUIDES[guideKey].steps.length
        ? 0
        : step - 1;
    Track(AnalyticsEvents.TourGuide.OpenedPage, {
      guide: guideKey,
      previousPage: "n/a",
      page: correctStep + 1,
    });
    setCurrentGuide({
      guideKey,
      step: correctStep,
      isForce: true,
    });
  }, []);

  const changeCurrentGuideStep = useCallback(
    (step: number) => {
      if (!currentGuide) {
        return;
      }
      Track(AnalyticsEvents.TourGuide.ClosedPage, {
        guide: currentGuide.guideKey,
        page: currentGuide.step + 1,
        newPage:
          step < GUIDES[currentGuide.guideKey].steps.length ? step + 1 : "n/a",
      });
      Track(AnalyticsEvents.TourGuide.OpenedPage, {
        guide: currentGuide.guideKey,
        previousPage: currentGuide.step + 1,
        page: step + 1,
      });
      setCurrentGuide({
        ...currentGuide,
        step,
      });
    },
    [currentGuide]
  );

  useHotkeys(
    "ArrowRight",
    () => {
      if (
        currentGuide &&
        currentGuide.step >= 0 &&
        currentGuide.step < GUIDES[currentGuide.guideKey].steps.length - 1
      ) {
        changeCurrentGuideStep(currentGuide.step + 1);
      }
    },
    [currentGuide, changeCurrentGuideStep]
  );
  useHotkeys(
    "ArrowLeft",
    () => {
      if (
        currentGuide &&
        currentGuide.step > 0 &&
        currentGuide.step < GUIDES[currentGuide.guideKey].steps.length
      ) {
        changeCurrentGuideStep(currentGuide.step - 1);
      }
    },
    [currentGuide, changeCurrentGuideStep]
  );

  const text = useMemo(() => {
    const texts = compiler(
      currentGuide && GUIDES[currentGuide.guideKey].steps[currentGuide.step]
        ? GUIDES[currentGuide.guideKey].steps[currentGuide.step].description
        : ""
    );
    if (!texts.props.children) {
      return "";
    }
    if (typeof texts.props.children === "string") {
      return `<span>${texts.props.children}</span>`;
    }
    let newText = "";
    (texts.props.children ?? []).forEach((item: any) => {
      if (typeof item === "string") {
        newText += item;
        return;
      }
      if (!item.type && !item.props.children) {
        return;
      }
      if (item.type && !item.props.children) {
        newText += `<${item.type} />`;
        return;
      }
      const style = item.props.style
        ? `style="${Object.keys(item.props.style).reduce(
            (acc, key) => `${acc}${key}:${item.props.style[key]};`,
            ""
          )}"`
        : "";
      const href = item.props.href ? `href="${item.props.href}"` : "";
      newText += `<span><${item.type} ${style} ${href}>${item.props.children}</${item.type}></span>`;
    });
    return newText;
  }, [currentGuide]);

  const tourGuideContextValue = useMemo(
    () => ({
      forceShowGuide,
    }),
    [forceShowGuide]
  );

  return (
    <TourGuideContext.Provider value={tourGuideContextValue}>
      {currentGuide && (
        <Modal autoFocus={false} isOpen={true} onClose={closeGuide}>
          <ModalOverlay />
          <ModalContent
            overflow="hidden"
            w={"640px"}
            maxW="80%"
            borderWidth={1}
            borderRadius={"2xl"}
            bgColor="backgroundSecondary.500"
            containerProps={{
              alignItems: "center",
              zIndex: "fullscreen",
            }}
          >
            <ModalCloseButton />
            <ModalBody p={0}>
              <VStack spacing={6}>
                <Image
                  w="100%"
                  alt={
                    GUIDES[currentGuide.guideKey].steps[currentGuide.step].title
                  }
                  aspectRatio="16 / 9"
                  fallback={<Skeleton w="100%" aspectRatio="16 / 9" />}
                  src={`/tour-guide/${currentGuide.guideKey}/${
                    currentGuide.step + 1
                  }.${
                    GUIDES[currentGuide.guideKey].steps[currentGuide.step]
                      .imageFormat ?? "png"
                  }`}
                />
                <VStack align="start" w="100%" px={8} spacing={3}>
                  <Text size="title.sm">
                    {
                      GUIDES[currentGuide.guideKey].steps[currentGuide.step]
                        .title
                    }
                  </Text>
                  <Text align="start" color="textSecondary" size="body.md">
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                  </Text>
                </VStack>
                <HStack justify="space-between" w="100%" pb={8} px={8}>
                  <HStack spacing={1.5}>
                    {GUIDES[currentGuide.guideKey].steps.map((_, index) => (
                      <Box
                        key={index}
                        w="8px"
                        h="8px"
                        borderRadius="full"
                        cursor="pointer"
                        bgColor={
                          index === currentGuide.step
                            ? "textPrimary"
                            : "whiteAlpha.400"
                        }
                        onClick={() => changeCurrentGuideStep(index)}
                      />
                    ))}
                  </HStack>

                  <HStack spacing={2}>
                    {currentGuide.step > 0 && (
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() =>
                          changeCurrentGuideStep(currentGuide.step - 1)
                        }
                      >
                        Prev.
                      </Button>
                    )}

                    <Button
                      size="sm"
                      onClick={() => {
                        if (
                          currentGuide.step ===
                          GUIDES[currentGuide.guideKey].steps.length - 1
                        ) {
                          Track(AnalyticsEvents.TourGuide.ClickedLearnMore, {
                            guide: currentGuide.guideKey,
                          });
                        } else {
                          changeCurrentGuideStep(currentGuide.step + 1);
                        }
                      }}
                      externalLink={
                        currentGuide.step ===
                        GUIDES[currentGuide.guideKey].steps.length - 1
                          ? GUIDES[currentGuide.guideKey].knowledgeCenterLink
                          : undefined
                      }
                    >
                      {currentGuide.step ===
                      GUIDES[currentGuide.guideKey].steps.length - 1
                        ? "Learn More"
                        : "Next"}
                    </Button>
                  </HStack>
                </HStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {children}
    </TourGuideContext.Provider>
  );
}

export function useTourGuideContext() {
  return useContext<TourGuideProviderContextValue>(TourGuideContext);
}
