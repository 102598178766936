import { ButtonProps } from "domains/ui/components/Button";
import { IconProps } from "domains/ui/components/Icon";

export type ToolItem = {
  id: string;
  iconId: IconProps["id"];
  title: string;
  subTitle: string;
  internalLink?: ButtonProps["internalLink"];
  soon?: boolean;
  featureFlag?: string;
};

export const TOOL_ITEMS: ToolItem[] = [
  {
    id: "generate",
    iconId: "Domains/Tools/Generate",
    title: "Generate",
    subTitle: "Generate consistent images with guided settings.",
    internalLink: "/images/new",
    soon: false,
  },

  {
    id: "compose",
    iconId: "Domains/Tools/Blend",
    title: "Compose",
    subTitle: "Merge multiple models into new, distinctive styles.",
    internalLink: "/compose/new",
  },
  {
    id: "train",
    iconId: "Domains/Tools/Train",
    title: "Train",
    subTitle: "Tailor custom AI models trained on your own data.",
    internalLink: "/models/new",
    soon: false,
  },
  {
    id: "live",
    iconId: "Domains/Canvas/Live",
    title: "Live",
    subTitle:
      "Generate style-consistent visuals via sketching and real-time rendering.",
    internalLink: {
      pathname: "/canvas/new",
      query: {
        live: "true",
      },
    },
  },
  {
    id: "upscale",
    iconId: "Domains/Tools/Upscale",
    title: "Enhance",
    subTitle: "Enhance image resolution for a sharper experience.",
    internalLink: "/enhance",
    soon: false,
  },
  {
    id: "expand",
    iconId: "Nav/Expand/Outline",
    title: "Expand",
    subTitle: "Easily extend your images via outpainting.",
    internalLink: "/expand",
    soon: false,
  },
  {
    id: "restyle",
    iconId: "Domains/Tools/Restyle",
    title: "Restyle",
    subTitle:
      "Transform an image or sketch by applying a style derived from other images.",
    internalLink: "/restyle",
    soon: false,
  },
  {
    id: "api",
    iconId: "Domains/Tools/Api",
    title: "API",
    subTitle:
      "Seamlessly integrate Scenario into your applications, tools, or workflows.",
    internalLink: {
      pathname: "/team",
      query: {
        tab: "api",
      },
    },
    soon: false,
  },
  {
    id: "canvas",
    iconId: "Domains/Tools/Canvas",
    title: "Design",
    subTitle: "Edit fine details & create variants with the Canvas.",
    internalLink: "/canvas",
    soon: false,
  },
  {
    id: "remove-background",
    iconId: "Domains/Tools/RemoveBackground",
    title: "Remove Background",
    subTitle: "Remove or replace backgrounds.",
    internalLink: "/background-remove",
    soon: false,
  },
  {
    id: "pixelate",
    iconId: "Domains/Tools/Pixelate",
    title: "Pixelate",
    subTitle: "Re-imagine your images in a pixelicious art style.",
    internalLink: "/pixelate",
    soon: false,
  },
  {
    id: "vectorize",
    iconId: "Domains/Tools/Vectorize",
    title: "Vectorize",
    subTitle: "Convert generations into crisp and scalable vectors.",
    internalLink: "/vectorize",
  },
  {
    id: "textures",
    iconId: "Domains/Tools/Textures",
    title: "Textures",
    subTitle: "Generate rich, seamless textures with intricate details.",
    internalLink: "/textures/new",
  },
  {
    id: "skyboxes",
    iconId: "Domains/Tools/Skyboxes",
    title: "Skyboxes",
    subTitle: "Set immersive scenes with high-quality backdrops.",
    internalLink: "/skyboxes",
  },
  {
    id: "3d",
    iconId: "Domains/Tools/3d",
    title: "Image to 3D",
    subTitle: "Bring your 2D visuals into a new dimension.",
    soon: true,
  },
  {
    id: "video",
    iconId: "Domains/Tools/Video",
    title: "Image to Video",
    subTitle: "Animate still images into  captivating video content.",
    soon: true,
  },
];
