import { useMemo } from "react";
import { useRouter } from "next/router";
import { useChatContext } from "domains/navigation/contexts/ChatProvider";
import { appShortcuts } from "domains/shortcuts/components/appShortcuts";
import { ShortcutsModal } from "domains/shortcuts/components/ShortcutsModal";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHotkeys } from "react-hotkeys-hook";

import {
  Menu,
  MenuButton,
  MenuList,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";

type ActionListItem = {
  id: string;
  label: string;
  externalLink?: ButtonProps["externalLink"];
  onClick?: "toggleChat" | "toggleShortcuts" | (() => void);
};

const ACTION_LIST: ActionListItem[] = [
  {
    id: "support",
    label: "Contact Us",
    externalLink:
      "https://docs.google.com/forms/d/e/1FAIpQLScTW4rxKfm5XgMX0WyjP6dXin1qrrsDEUN8EL9BfG7WNx1Qig/viewform?usp=share_link",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedSupport);
    },
  },
  {
    id: "knowledgeCenter",
    label: "Knowledge Center",
    externalLink: "https://help.scenario.com",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedHelpCenter);
    },
  },
  {
    id: "chat",
    label: "Chat Support",
    onClick: "toggleChat",
  },
  {
    id: "shortcuts",
    label: "Keyboard Shortcuts",
    onClick: "toggleShortcuts",
  },
];

export default function HelpButtonDropdown() {
  const {
    isOpen: isShortcutsOpen,
    onOpen: onShortcutsOpen,
    onClose: onShortcutsClose,
    onToggle: onShortcutsToggle,
  } = useDisclosure();
  const { selectedTeam } = useTeamContext();
  const { isChatVisible, setIsChatVisible } = useChatContext();
  const router = useRouter();

  useHotkeys(
    appShortcuts.global.shortcuts.shortcutHelp.shortcut,
    (e) => {
      if ((e.target as HTMLInputElement | undefined)?.id.includes("inkeep")) {
        return;
      }
      onShortcutsToggle();
    },
    { enabled: router.pathname !== "/canvas/[id]" && !router.query.openAssetId }
  );

  const actionList = useMemo(
    () =>
      ACTION_LIST.filter((item) => {
        return !(item.id === "support" && selectedTeam.isFreePlan);
      }),
    [selectedTeam.isFreePlan]
  );

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          pos="relative"
          w="36px"
          h="36px"
          p={0}
          borderRadius="full"
          _hover={{
            bgColor: "backgroundTertiary.500",
          }}
          variant="unstyled"
        >
          <Icon id="Layout/Help" h="20px" color="textSecondary" />
        </MenuButton>

        <MenuList overflowY="auto" w="220px" maxH="80vh" px={2}>
          {actionList.map((item) => {
            const onClick = (() => {
              if (item.onClick === "toggleChat") {
                return () => {
                  if (isChatVisible) {
                    setIsChatVisible(false);
                    return;
                  }
                  setIsChatVisible(true);
                  Track(AnalyticsEvents.Navigation.ClickedChat);
                };
              } else if (item.onClick === "toggleShortcuts") {
                return onShortcutsOpen;
              } else if (typeof item.onClick === "function") {
                return item.onClick;
              }
            })();

            return (
              <MenuItem
                key={item.id}
                externalLink={item.externalLink}
                onClick={onClick}
                text={item.label}
              />
            );
          })}
        </MenuList>
      </Menu>
      <Portal>
        <ShortcutsModal
          isOpen={isShortcutsOpen}
          onClose={onShortcutsClose}
          shortcuts={appShortcuts}
        />
      </Portal>
    </>
  );
}
