import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import EventEmitter from "events";

export const ON_IMAGE_PASTED = "ON_IMAGE_PASTED";

interface ClipboardContextValue {
  images: string[];
  addImagePasteListener: (callback: (images: string[]) => void) => void;
  removeImagePasteListener: (callback: (images: string[]) => void) => void;
}

export const ClipboardContext = createContext<ClipboardContextValue>({
  images: [],
  addImagePasteListener: () => {},
  removeImagePasteListener: () => {},
});

export function ClipboardProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const [images, setImages] = useState<string[]>([]);
  const [callbacks, setCallbacks] = useState<((images: string[]) => void)[]>(
    []
  );

  const ImagePasteEmitter: EventEmitter = useMemo(() => {
    return new EventEmitter();
  }, []);

  // ----------------------------------

  const addImagePasteListener = useCallback(
    (callback: (images: string[]) => void) => {
      setCallbacks((callbacks) => [callback, ...callbacks]);
    },
    [setCallbacks]
  );

  const removeImagePasteListener = useCallback(
    (callback: (images: string[]) => void) => {
      setCallbacks((callbacks) =>
        callbacks.filter((item) => item !== callback)
      );
    },
    [setCallbacks]
  );

  // ----------------------------------

  const handlePaste = useCallback(
    async (event: any) => {
      const clipboardData: DataTransfer = event.clipboardData;

      const allImages: File[] = [];
      for (let i = 0; i < clipboardData.items.length; i++) {
        if (!clipboardData.items[i].type.includes("image")) {
          continue;
        }

        const imageFile = clipboardData.items[i].getAsFile();
        if (imageFile === null) continue;
        allImages.push(imageFile);
      }

      if (allImages.length === 0) {
        return;
      }

      event.preventDefault();

      const images: string[] = await Promise.all(
        allImages.map(async (item) => {
          return new Promise((resolve, reject) => {
            const image = new FileReader();
            image.onload = (event: any) => {
              resolve(event.target.result);
            };

            image.onerror = (event: any) => {
              reject(event);
            };
            image.readAsDataURL(item);
          });
        })
      );

      setImages(images);
      ImagePasteEmitter.emit(ON_IMAGE_PASTED, images);
    },
    [ImagePasteEmitter]
  );

  // ----------------------------------

  useEffect(() => {
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste]);

  useEffect(() => {
    if (callbacks.length === 0) {
      return;
    }

    ImagePasteEmitter.on(ON_IMAGE_PASTED, callbacks[0]);
    return () => {
      ImagePasteEmitter.off(ON_IMAGE_PASTED, callbacks[0]);
    };
  }, [ImagePasteEmitter, callbacks]);

  // ----------------------------------

  return (
    <ClipboardContext.Provider
      value={{
        images,
        addImagePasteListener,
        removeImagePasteListener,
      }}
    >
      {children}
    </ClipboardContext.Provider>
  );
}

export function useClipboardContext() {
  return useContext<ClipboardContextValue>(ClipboardContext);
}
