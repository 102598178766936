import { FileThumbnail } from "domains/file-manager/interfaces";
import { Team } from "domains/teams/interfaces/Team";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

export const DEFAULT_MODEL_THUMBNAIL = "/ui/model-thumbnail-placeholder.png";

export function getModelThumbnail(
  model: GetModelsByModelIdApiResponse["model"] | undefined,
  dimensions?: { width?: number; height?: number }
): {
  id: string | undefined;
  url: FileThumbnail;
} {
  let thumbnail: string | undefined;
  let assetId: string | undefined;

  if (model?.thumbnail) {
    thumbnail = model.thumbnail.url;
    assetId = model.thumbnail.assetId;
  } else if (model?.trainingImages?.length) {
    thumbnail =
      model.trainingImages[model.trainingImages.length - 1].downloadUrl;
    assetId = model.trainingImages[model.trainingImages.length - 1].id;
  }

  if (thumbnail) {
    thumbnail = `${thumbnail}&quality=80&format=jpeg`;
    if (dimensions?.width) {
      thumbnail = `${thumbnail}&width=${dimensions?.width}`;
    }
    if (dimensions?.height) {
      thumbnail = `${thumbnail}&height=${dimensions?.height}`;
    }
  }

  return {
    id: assetId,
    url: (thumbnail ?? DEFAULT_MODEL_THUMBNAIL) as FileThumbnail,
  };
}

/**
 * Checks if the given model belongs to the specified team.
 * @param model - The model to check ownership for.
 * @param team - The team to check ownership against.
 * @returns True if the model belongs to the team, false otherwise.
 */
export function isOwner(
  model: GetModelsByModelIdApiResponse["model"] | undefined,
  team: Team
): boolean {
  if (!model) {
    return false;
  }
  return model.ownerId === team.id.replace("%7C", "|");
}

export function getIsModelSdxl(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.type) {
    return false;
  }
  return model.type.startsWith("sd-xl");
}

export function getIsModelFlux(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.type) {
    return false;
  }
  return model.type.includes("flux");
}

export function getIsModelFluxDev(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.id) {
    return false;
  }
  return model.id === "flux.1-dev";
}

export function getIsModelFluxPro(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.id) {
    return false;
  }
  return model.id === "flux.1-pro";
}

export function getIsModelFluxPro1_1(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.id) {
    return false;
  }
  return model.id === "flux1.1-pro";
}

export function getIsModelFluxPro1_1Ultra(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.id) {
    return false;
  }
  return model.id === "flux.1.1-pro-ultra";
}

export function getIsModelFluxSchnell(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model?.id) {
    return false;
  }
  return model.id === "flux.1-schnell";
}

export function getIsModelWithBase(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  return getIsModelSdxl(model) && model?.id !== "stable-diffusion-xl-base-1.0";
}

export function getIsBaseModel(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model) {
    return false;
  }
  return (
    getIsModelFluxDev(model) ||
    getIsModelFluxPro(model) ||
    getIsModelFluxPro1_1(model) ||
    getIsModelFluxPro1_1Ultra(model) ||
    getIsModelFluxSchnell(model) ||
    model.id === "stable-diffusion-xl-base-1.0" ||
    model.id === "stable-diffusion-v1-5"
  );
}
