import { PropsWithChildren, useMemo } from "react";
import BackgroundTaskIcon from "domains/background-tasks/components/ElementBackgroundTaskIcon";
import { useBackgroundTaskContext } from "domains/background-tasks/contexts/BackgroundTaskProvider";
import { BackgroundTask } from "domains/background-tasks/interfaces/BackgroundTask";
import { blinkAnimation } from "domains/commons/style";
import Button, { ButtonProps, IconButton } from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Center,
  CircularProgress,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export default function BackgroundTasksDropdown() {
  const { recentTasks, isOpen, isLoading, clearTask, open, close } =
    useBackgroundTaskContext();

  const sections = useMemo(() => {
    const newSections: {
      id: string;
      title: string;
      tasks: BackgroundTask[];
    }[] = [
      { id: "modelTraining", title: "Model Training", tasks: [] },
      { id: "modelUpload", title: "Model Upload", tasks: [] },
      { id: "download", title: "Download", tasks: [] },
      { id: "generation", title: "Generations", tasks: [] },
    ];

    recentTasks.forEach((task) => {
      const sectionId =
        {
          modelTraining: "modelTraining",
          modelUpload: "modelUpload",
          modelDownload: "download",
          assetsDownload: "download",
        }[task.type as string] ?? "generation";
      const sectionIdx = newSections.findIndex(
        (section) => section.id === sectionId
      );
      newSections[sectionIdx].tasks.push(task);
    });

    return newSections.filter((section) => !!section.tasks.length);
  }, [recentTasks]);

  return (
    <Popover isOpen={isOpen} onClose={close} placement="bottom-end">
      <Tooltip label="Recent Tasks">
        <PopoverTrigger>
          <IconButton
            pos="relative"
            w="36px"
            h="36px"
            p={0}
            borderRadius="full"
            _hover={{
              bgColor: "backgroundTertiary.500",
            }}
            aria-label="Recent Tasks"
            onClick={() => (isOpen ? close() : open())}
            variant="unstyled"
          >
            <Icon
              id="Ui/BackgroundTasks"
              animation={
                isLoading ? `${blinkAnimation} 1s linear infinite` : undefined
              }
              h="16px"
              color={isLoading ? "textPrimary" : "textSecondary"}
            />
          </IconButton>
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent borderColor="border.500">
        <PopoverBody p={0} borderRadius="md" bgColor="background.500">
          <Box>
            <HStack
              w="100%"
              h="52px"
              px={4}
              borderColor="border.500"
              borderBottomWidth={1}
            >
              <Text flex={1} color="textPrimary" size="body.bold.md">
                Recent Tasks
              </Text>

              <IconButton
                mr={-1}
                aria-label="Close"
                colorScheme="white"
                onClick={() => close()}
                variant="ghost"
                size="xs"
              >
                <Icon id="Ui/Cross" height="10px" />
              </IconButton>
            </HStack>

            <VStack align="stretch" py={3} spacing={3}>
              {sections.map((section) => (
                <TaskSection key={section.id} title={section.title}>
                  {section.tasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      taskId={task.id}
                      type={task.type}
                      label={task.label}
                      status={task.status}
                      internalLink={task.internalLink}
                      externalLink={task.externalLink}
                      progress={task.progress}
                      errorMessage={task.errorMessage}
                      onClear={clearTask}
                    />
                  ))}
                </TaskSection>
              ))}

              {!sections.length && (
                <Text px={4} color="textSecondary" size="body.md">
                  No recent tasks
                </Text>
              )}
            </VStack>

            <Divider />

            <Box px={4} py={2}>
              <Button
                variant="link"
                size="sm"
                rightIcon={<Icon id="Ui/Link" h="12px" />}
                internalLink="/recent-tasks"
                target="_blank"
                onClick={() => close()}
              >
                View All
              </Button>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

// ------------------------------------

interface TaskSectionProps extends PropsWithChildren {
  title: React.ReactNode;
}

function TaskSection({ title, children }: TaskSectionProps) {
  return (
    <VStack align="stretch" spacing={1}>
      <Text px={4} color="textPrimary" size="body.md">
        {title}
      </Text>
      <VStack align="stretch" spacing={0}>
        {children}
      </VStack>
    </VStack>
  );
}

// ------------------------------------

interface TaskItemProps {
  taskId: string;
  type: BackgroundTask["type"];
  label: string;
  status: BackgroundTask["status"];
  internalLink?: ButtonProps["internalLink"];
  externalLink?: ButtonProps["externalLink"];
  progress?: number;
  errorMessage?: string;
  onClear?: (id: string) => void;
}

function TaskItem({
  taskId,
  type,
  label,
  status,
  internalLink,
  externalLink,
  progress,
  errorMessage,
  onClear,
}: TaskItemProps) {
  const isDownload = ["modelDownload", "assetsDownload"].includes(type);

  return (
    <Tooltip
      hasArrow
      isOpen={status === "failed" ? undefined : false}
      label={errorMessage}
      placement="left"
    >
      <HStack
        h="36px"
        px={4}
        _hover={status === "failed" ? { bgColor: "whiteAlpha.50" } : {}}
        data-group
        spacing={3}
      >
        <Center w="20px">
          <BackgroundTaskIcon type={type} />
        </Center>

        <Text flex={1} color="textSecondary" isTruncated size="body.md">
          {label}
        </Text>

        {!!(onClear || internalLink || externalLink) && (
          <HStack display="none" _groupHover={{ display: "flex" }}>
            {(internalLink || externalLink) && (
              <Tooltip
                hasArrow
                label={isDownload ? "Download" : "View task"}
                placement="top"
              >
                <IconButton
                  mx={-1}
                  aria-label={isDownload ? "Download" : "View task"}
                  colorScheme="white"
                  internalLink={internalLink}
                  externalLink={externalLink}
                  target="_blank"
                  size="xs"
                  variant="ghost"
                >
                  <Icon id="Ui/Link" h="12px" />
                </IconButton>
              </Tooltip>
            )}

            {!!onClear && (
              <Tooltip hasArrow label="Clear" placement="top">
                <IconButton
                  mx={-1}
                  aria-label="Clear"
                  colorScheme="white"
                  onClick={() => onClear(taskId)}
                  size="xs"
                  variant="ghost"
                >
                  <Icon id="Ui/Cross" h="10px" />
                </IconButton>
              </Tooltip>
            )}
          </HStack>
        )}

        <Box _groupHover={{ display: "none" }}>
          {(() => {
            if (status === "succeeded") {
              return <Icon id="Toast/Success" h="18px" color="success.600" />;
            } else if (status === "failed") {
              return <Icon id="Toast/Error" h="18px" color="danger.500" />;
            } else if (status === "loading") {
              return (
                <CircularProgress
                  color="textPrimary"
                  animation={
                    progress
                      ? `${blinkAnimation} 1s linear infinite`
                      : undefined
                  }
                  capIsRound
                  isIndeterminate={progress === undefined}
                  size="20px"
                  thickness="15px"
                  trackColor="whiteAlpha.200"
                  value={progress}
                />
              );
            }
          })()}
        </Box>
      </HStack>
    </Tooltip>
  );
}
