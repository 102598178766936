import { getModelThumbnail } from "domains/models/utils";
import { PostSearchApiTransformedResponse } from "domains/search/interfaces/Search";
import {
  GetAssetsApiResponse,
  GetAssetsByAssetIdApiResponse,
  GetInferencesApiResponse,
  GetJobIdApiResponse,
  GetModelsByModelIdApiResponse,
} from "infra/api/generated/api";

import { FilePreviewProps } from "./components/FilePreview";

export interface FileTypeBase {
  id: string;
  name: string;
  thumbnail: string;
  width: number;
  height: number;
}

// This is to make sure we always create the thumbnail with the function correct function so that it will compress
export type FileThumbnail = string & {
  placeholder: any;
};

export interface FileCanvasType extends FileTypeBase {
  type: "canvas";
  thumbnail: FileThumbnail;

  meta: GetAssetsByAssetIdApiResponse["asset"];
}

export interface FileImageType extends FileTypeBase {
  type: "image";
  thumbnail: FileThumbnail;
  status:
    | "success"
    | "error"
    | "processing"
    | "queued"
    | "warming-up"
    | "placeholder";

  meta: GetAssetsByAssetIdApiResponse["asset"];
}

export interface FileModelType extends FileTypeBase {
  type: "model";
  thumbnail: FileThumbnail;
  exampleAssets?: FileImageType[];

  meta: GetModelsByModelIdApiResponse["model"];
}

export type Action<F extends FileType> = {
  kind: ActionKind | ActionKind[];
  label: string;
  shortcut?: (e: KeyboardEvent) => boolean;
  onAction: (files: F[]) => void;
  withConfirmation?: boolean;
  confirmationMessage?: string;
  waitUntilDone?: boolean;
  Component: (props: {
    files: F[];
    onAction: (files: F[]) => void;
    isViewable: boolean;
  }) => JSX.Element;
};

export type FileType = FileImageType | FileModelType | FileCanvasType;

/**
 * Each action can be of a different kind, and each kind has a different UI
 * selectionBar: the action will be shown in the selection bar
 * quickAction: the action will be shown in the quick action menu on the file preview
 */
export type ActionKind = "selectionBar" | "quickAction";

export type FileHandler<F extends FileType> = {
  onOpen?: (file: F) => void;

  icon?: JSX.Element; /////// NOT USED

  actions?: Action<F>[];

  /** Component to be rendered when there are no files to show */
  EmptyState?: JSX.Element;

  /** Component to display the file, alway prefer wrapping the domains/file-manager/components/FilePreview by adding logic above it like this
   * @example
   * const CustomFilePreview = (props: FilePreviewProps) => {
   *
   * const [customLogic, setCustomLogic] = useState(false)
   *
   * ...
   *
   * return (
   * <FilePreview {...props} isLoading={customLogic}>
   * )
   * }
   * */
  FilePreview?: (props: FilePreviewProps<F>) => React.ReactElement;

  // TODO: Add more events, not used for now
  // onShare?: () => void;
  // onContextMenu?: () => void;
  // onMove?: () => void;
  // onDisabled?: () => void;
  // onDragStart?: () => void;
  // onDragEnd?: () => void;
  // onRename?: () => void;
  // onCopy?: () => void;
  // onCut?: () => void;
  // onPaste?: () => void;
  // onDownload?: () => void;
  // onUpload?: () => void;
  onDelete?: (files: F[]) => void;
};

export function mapModelsToFiles(
  models: GetModelsByModelIdApiResponse["model"][]
): FileModelType[] {
  return models.map((model) => ({
    type: "model",
    id: model.id,
    name: model.name || "Untitled",
    thumbnail: getModelThumbnail(model).url,
    width: 512,
    height: 512,
    meta: {
      ...model,
      // don't trust api.ts, sometimes type is not there
      type: model.type ?? "sd-1_5",
    },
  }));
}

export const getImageThumbnail = ({
  url,
  type,
  width,
}: {
  url: string;
  type: string;
  width?: number;
}): FileThumbnail => {
  if (!url) {
    return "" as FileThumbnail;
  }
  if (!url.includes("/assets-transform/")) {
    return url as FileThumbnail;
  }
  let thumbnail = url + "&quality=80";
  if (type !== "background-removal") {
    thumbnail += "&format=jpeg";
  }
  if (width) {
    thumbnail += `&width=${width}`;
  }
  return thumbnail as FileThumbnail;
};

export const mapSearchResultsToImagesFiles = (
  images: PostSearchApiTransformedResponse["results"]
): FileImageType[] => {
  return images.map((image) => {
    const isTexture = image.assetType === "texture";
    const width = image.width ?? 512;
    const height = image.height ?? 512;
    return {
      type: "image",
      id: image.id,
      name: image.prompt ?? "",
      thumbnail: getImageThumbnail({
        url: image.thumbnailUrl ?? "",
        type: image.assetType ?? "",
      }),
      width: isTexture ? height : width,
      height,
      status: "success",
      meta: {
        createdAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
        metadata: {
          kind: "image",
          type: image.assetType ?? "inference-img2img",
          negativePrompt: image.negativePrompt,
          name: image.name,
          prompt: image.prompt,
        },
        description: image.captioning,
        url: image.thumbnailUrl ?? "",
        tags: image.tags,
        updatedAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
        collectionIds: image.collectionIds ?? [],
        privacy: "private",
        id: image.id,
        mimeType: "",
        authorId: "",
        ownerId: "",
        status: "success",
        nsfw: image.nsfw,
        editCapabilities: [],
      },
    };
  });
};

export const mapSearchResultsToModelsFiles = (
  models: PostSearchApiTransformedResponse["results"]
): FileModelType[] => {
  return models.map((model) => ({
    id: model.id,
    name: model.name || "Untitled",
    type: "model",
    thumbnail: getModelThumbnail({
      ...(model.thumbnailUrl
        ? {
            thumbnail: {
              url: model.thumbnailUrl,
            },
          }
        : {}),
      trainingImages: model.trainingImages ?? undefined,
    } as any).url,
    width: 512,
    height: 512,
    meta: {
      id: model.id,
      type: "sd-1_5", // Should be replaced with search model type
      createdAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
      name: model.name ?? "",
      tags: [],
      privacy: "public",
      status: "trained",
      trainingImages: model.trainingImages ?? undefined,
      trainingImagesNumber: model.trainingImages?.length ?? 0,
      collectionIds: model.collectionIds ?? [],
      updatedAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
      exampleAssetIds: [],
      source: "scenario",
      capabilities: [],
    },
  }));
};

export const mapSearchResultsToCanvasesFiles = (
  canvases: PostSearchApiTransformedResponse["results"]
): FileCanvasType[] => {
  return canvases.map((canvas) => ({
    type: "canvas",
    id: canvas.id,
    name: canvas.name ?? "Untitled",
    thumbnail: getImageThumbnail({
      url: canvas.thumbnailUrl ?? "",
      type: "canvas",
    }),
    width: 512,
    height: 512,
    meta: {
      createdAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
      metadata: {
        kind: "json",
        type: "canvas",
        name: canvas.name,
      },
      description: canvas.captioning,
      url: canvas.thumbnailUrl ?? "",
      tags: canvas.tags,
      updatedAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
      collectionIds: canvas.collectionIds ?? [],
      privacy: "private",
      id: canvas.id,
      mimeType: "",
      authorId: "",
      ownerId: "",
      status: "success",
      nsfw: canvas.nsfw,
      editCapabilities: [],
    },
  }));
};

export const mapAssetsToImagesFiles = (
  assets: GetAssetsApiResponse["assets"]
): FileImageType[] => {
  return assets.map((asset) => {
    const isTexture = asset.metadata.type === "texture";
    const width = asset.metadata.width ?? 512;
    const height = asset.metadata.height ?? 512;
    return {
      id: asset.id,
      name: asset.metadata.prompt ?? "",
      type: "image",
      thumbnail: getImageThumbnail({
        url: asset.url,
        type: asset.metadata.type,
      }),
      width: isTexture ? height : width,
      height,
      meta: asset,
      status: asset.status === "pending" ? "processing" : asset.status,
    };
  });
};

export const mapInferencesToImagesFiles = (
  inferences: GetInferencesApiResponse["inferences"]
): FileImageType[] => {
  const files: FileImageType[] = [];
  const inferenceStatusToAssetStatus: {
    [key in GetInferencesApiResponse["inferences"][0]["status"]]: FileImageType["status"];
  } = {
    succeeded: "success",
    failed: "error",
    canceled: "error",
    queued: "queued",
    "in-progress": "processing",
    "model-loading": "warming-up",
    new: "queued",
  };

  for (const inference of inferences) {
    for (const image of inference.images) {
      files.push({
        type: "image",
        id: image.id,
        name: inference.displayPrompt,
        thumbnail: getImageThumbnail({
          url: image.url,
          type: "inference-txt2img",
        }),
        width: inference.parameters.width ?? 512,
        height: inference.parameters.height ?? 512,
        status:
          image.url && !inference.parameters.intermediateImages
            ? "success"
            : inferenceStatusToAssetStatus[inference.status],
        meta: {
          createdAt: new Date(inference.createdAt).toString(),
          metadata: {
            kind: "image",
            type: "inference-txt2img",
            negativePrompt: inference.parameters.negativePrompt,
            name: inference.displayPrompt,
            prompt: inference.parameters.prompt,
            seed: image.seed,
            inferenceId: inference.id,
            modelId: inference.modelId,
            width: inference.parameters.width ?? 512,
            height: inference.parameters.height ?? 512,
          },
          description: "",
          url: image.url,
          tags: [],
          updatedAt: new Date(inference.createdAt).toString(),
          collectionIds: [],
          privacy: "private",
          id: image.id,
          mimeType: "",
          authorId: "",
          ownerId: "",
          status: (() => {
            const status = inferenceStatusToAssetStatus[inference.status];
            if (status === "error" || status === "success") {
              return status;
            }
            return "pending";
          })(),
          editCapabilities: Object.keys(
            {} as {
              [K in GetAssetsApiResponse["assets"][0]["editCapabilities"][number]]: null;
            }
          ) as GetAssetsApiResponse["assets"][0]["editCapabilities"],
        },
      });
    }
  }

  return files;
};

export const mapAssetsToCanvasFiles = (
  assets: GetAssetsApiResponse["assets"]
): FileCanvasType[] =>
  assets.map((asset) => {
    return {
      type: "canvas",
      id: asset.id,
      name: asset.metadata.name || "Untitled",
      thumbnail: getImageThumbnail({
        url: asset.metadata.thumbnail?.url ?? "",
        type: asset.metadata.type,
      }),
      width: 512,
      height: 512,
      meta: asset,
    };
  });

export const getPlaceholderImageFilesFromJob = ({
  job,
  amount,
  width,
  height,
}: {
  job: NonNullable<GetJobIdApiResponse["job"]>;
  amount: number;
  width: number;
  height: number;
}): FileImageType[] => {
  const files: FileImageType[] = [];

  const inferenceStatusToAssetStatus: {
    [key in NonNullable<
      GetJobIdApiResponse["job"]
    >["status"]]: FileImageType["status"];
  } = {
    canceled: "error",
    failure: "error",
    "in-progress": "processing",
    queued: "queued",
    success: "success",
    "warming-up": "warming-up",
  };

  for (let i = 0; i < amount; i++) {
    files.push({
      type: "image",
      id: `placeholder-${job.jobId}-${i}`,
      name: "",
      thumbnail: "" as FileThumbnail,
      width,
      height,
      status: inferenceStatusToAssetStatus[job.status],
      meta: {
        createdAt: new Date("9999-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
        metadata: {
          kind: "image",
          type: "inference-txt2img",
          negativePrompt: "",
          name: "",
          prompt: "",
          progressPercent: job.progress !== undefined ? job.progress * 100 : 0,
          parentJobId: job.jobId,
        },
        description: "",
        url: "",
        tags: [],
        updatedAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
        collectionIds: [],
        privacy: "private",
        id: `placeholder-${job.jobId}-${i}`,
        mimeType: "",
        authorId: "",
        ownerId: "",
        status: (() => {
          const status = inferenceStatusToAssetStatus[job.status];
          if (status === "error" || status === "success") {
            return status;
          }
          return "pending";
        })(),
        editCapabilities: [],
      },
    });
  }

  return files;
};
