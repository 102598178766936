import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import { AppShortcutCategory, ShortcutItem } from "./appShortcuts";
import { ShortcutKBD } from "./Shorcut";

const isShortcutCategory = (
  item: AppShortcutCategory | ShortcutItem
): item is AppShortcutCategory => {
  return "title" in item && "shortcuts" in item;
};

type ShortcutsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  shortcuts: Record<string, AppShortcutCategory | ShortcutItem>;
};

export function ShortcutsModal({
  isOpen,
  onClose,
  shortcuts,
}: ShortcutsModalProps) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} variant={"action"}>
      <ModalOverlay />
      <ModalContent w="450px" maxH="80vh">
        <ModalHeader>Keyboard Shortcuts</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" pb={4}>
          <VStack align="stretch" gap={4}>
            {Object.entries(shortcuts).map(([categoryKey, item]) =>
              isShortcutCategory(item) ? (
                <VStack key={categoryKey} align="stretch" pt={4} spacing={2}>
                  <Text color="textPrimary" size="body.bolder.md">
                    {item.title}
                  </Text>
                  {Object.entries(item.shortcuts).map(
                    ([shortcutKey, shortcut]) => (
                      <HStack
                        key={categoryKey + "-" + shortcutKey}
                        align="center"
                        justify="start"
                      >
                        <Text
                          w="70%"
                          color="textSecondary"
                          whiteSpace="nowrap"
                          size="body.md"
                        >
                          {shortcut.description}
                        </Text>
                        <ShortcutKBD shortcut={shortcut.shortcut} />
                      </HStack>
                    )
                  )}
                </VStack>
              ) : (
                <HStack key={categoryKey} align="center" justify="start">
                  <Text
                    w="70%"
                    color="textSecondary"
                    whiteSpace="nowrap"
                    size="body.md"
                  >
                    {item.description}
                  </Text>
                  <ShortcutKBD shortcut={item.shortcut} />
                </HStack>
              )
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
