import { useEffect, useRef, useState } from "react";
import { localStorageService } from "domains/infra/local-storage/localStorageService";
import { isEqual } from "lodash";

export enum PersistedStateKey {
  MODELS_FILTER_FAMILIES = "modelsFilterFamilies",
  MODELS_FILTER_BASES = "modelsFilterBases",
  MODELS_FILTER_PRESETS = "modelsFilterPresets",
  MODELS_FILTER_TAGS = "modelsFilterTags",
  NAV_BAR_SETTINGS = "navBarSettings",
  SIDEBAR_OPEN = "sidebarOpen",
  SELECTED_TEAM = "selectedTeam",
  WAITING_SUBSCRIPTION = "waitingSubscription",
  FILE_MANAGER_VIEW = "fileManagerView",
  FILE_MANAGER_SLAB = "fileManagerSlab",
  FILE_MANAGER_MODEL = "fileManagerModel",
  FILE_MANAGER_IMAGE = "fileManagerImage",
  FILE_MANAGER_IMAGE_V2 = "fileManagerImageV2",
  FILE_MANAGER_CANVAS = "fileManagerCanvas",
  FILE_MANAGER_SKYBOX = "fileManagerSkybox",
  FILE_MANAGER_SKYBOX_V2 = "fileManagerSkyboxV2",
  FILE_MANAGER_TEXTURE = "fileManagerTexture",
  FILE_MANAGER_TEXTURE_V2 = "fileManagerTextureV2",
  FILE_MANAGER_FILTER_AUTHOR = "fileManagerFilterAuthor",
  PANEL_EXTENDED = "panelExtended",
  CANVAS_CROP_IMAGE_WHEN_EXPORTING = "cropImageWhenExporting",
  MODELS_TAB = "modelsTab",
  CANVAS_LAYER_PANEL_COLLAPSED = "canvasLayerPanelCollapsed",
  CANVAS_TOOL_OPTIONS = "canvasToolOptions",
  COLLAPSED_ASIDE_SECTIONS = "collapsedAsideSections",
  COLLAPSED_SIDEBAR_SECTIONS = "collapsedSidebarSections",
  ASSET_ZOOM_ASIDE_OPEN = "assetZoomAsideOpen",
  ASSET_ZOOM_GALLERY_OPEN = "assetZoomGalleryOpen",
  FILE_MANAGER_MODEL_EXAMPLES = "fileManagerModelExamples",
  ASSET_ZOOM_IS_COMPARING = "assetZoomIsComparing",
  LOGIN_PAGE_IDENTIFIER = "loginPageIdentifier",
  TRAIN_SHOW_FIRST_REVIEW_CAPTION_MODAL = "trainShowFirstReviewCaptionModal",
  ASSET_ZOOM_DOWNLOAD_FORMAT = "assetZoomDownloadFormat",
  // IMAGES_TYPES_FILTER = "imagesTypesFilter", ///////// Add migration
  IMAGES_ASSET_TYPES_FILTER = "imagesAssetTypesFilter",
  IMAGES_JOB_TYPES_FILTER = "imagesJobTypesFilter",
  TEXTURES_TYPES_FILTER = "texturesTypesFilter",
  SKYBOXES_TYPES_FILTER = "skyboxesTypesFilter",
  TEXTURE_VIEWER_SETTINGS = "textureViewerSettings",
  TEXTURE_AUTO_ROTATE = "textureAutoRotate",
  LAST_MODEL_ID = "lastModelId",
}

export default function usePersistedState<T>(
  key: PersistedStateKey | undefined,
  options: {
    defaultValue: T;
    forceValue?: T;
    transform?: (value: any) => T | undefined;
  }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const localStorageRef = useRef(localStorageService);
  const keyRef = useRef(key);

  const [state, setState] = useState<T>(() => {
    if (options.forceValue) {
      return options.forceValue;
    }
    const persistedState = keyRef.current
      ? localStorageRef.current.get(keyRef.current)
      : undefined;
    const finalState =
      options.transform && persistedState !== undefined
        ? options.transform(persistedState)
        : persistedState;
    return (finalState !== undefined ? finalState : options.defaultValue) as T;
  });

  useEffect(() => {
    if (!keyRef.current) {
      return;
    }
    const persistedState = localStorageRef.current.get(keyRef.current);
    if (!isEqual(persistedState, state)) {
      localStorageRef.current.set(keyRef.current, state);
    }
  }, [state]);

  return [state, setState];
}
