import { UseAllAssetsArgs } from "domains/assets/hooks/useAllAssets";

export type AssetType = "image" | "texture" | "skybox" | "all";

export type FilterAssetCollectionValue = string;
export interface FilterAssetCollectionOption {
  label: string;
  value: string;
}

// ------------------------------------

export const FILTER_IMAGE_TYPES_KEYS = [
  "image:inference",
  "image:backgroundRemoved",
  "image:reframe",
  "image:pixelated",
  "image:uploaded",
  "image:upscaled",
  "image:vectorization",
  "image:detection",
  "image:restyle",
  "image:canvasExport",
] as const;
export const FILTER_IMAGE_TYPES_KEYS_ONLY_GENERATIONS =
  FILTER_IMAGE_TYPES_KEYS.filter(
    (key) =>
      key !== "image:uploaded" &&
      key !== "image:canvasExport" &&
      key !== "image:detection"
  );
export type FilterImageTypeKey = (typeof FILTER_IMAGE_TYPES_KEYS)[number];

export const FILTER_TEXTURE_TYPES_KEYS = [
  "texture:inference",
  "texture:textureSet",
  "texture:textureMaps",
  "texture:upscaled",
] as const;
export type FilterTextureTypeKey = (typeof FILTER_TEXTURE_TYPES_KEYS)[number];

export const FILTER_SKYBOX_TYPES_KEY = [
  "skybox:inference",
  "skybox:upscaled",
] as const;
export type FilterSkyboxTypeKey = (typeof FILTER_SKYBOX_TYPES_KEY)[number];

export interface FilterAssetType {
  label: string;
  value: NonNullable<UseAllAssetsArgs["types"]>;
}
export type FilterAssetTypeKey =
  | FilterImageTypeKey
  | FilterTextureTypeKey
  | FilterSkyboxTypeKey;
export type FilterAssetTypeMap = {
  [key in FilterAssetTypeKey]: FilterAssetType;
};

export const FILTER_ASSET_TYPES: FilterAssetTypeMap = {
  "image:inference": {
    label: "Generate",
    value: [
      "inference-controlnet",
      "inference-controlnet-img2img",
      "inference-controlnet-inpaint",
      "inference-controlnet-inpaint-ip-adapter",
      "inference-controlnet-ip-adapter",
      "inference-controlnet-reference",
      "inference-img2img",
      "inference-img2img-ip-adapter",
      "inference-inpaint",
      "inference-inpaint-ip-adapter",
      "inference-reference",
      "inference-txt2img",
      "inference-txt2img-ip-adapter",
    ],
  },
  "image:backgroundRemoved": {
    label: "Remove Background",
    value: ["background-removal"],
  },
  "image:pixelated": { label: "Pixelate", value: ["pixelization"] },
  "image:uploaded": { label: "Upload", value: ["uploaded"] },
  "image:upscaled": { label: "Enhance", value: ["upscale"] },
  "image:reframe": { label: "Expand", value: ["reframe"] },
  "image:vectorization": { label: "Vectorize", value: ["vectorization"] },
  "image:detection": { label: "Mode Maps", value: ["detection"] },
  "image:restyle": { label: "Restyle", value: ["restyle"] },
  "image:canvasExport": { label: "Canvas Export", value: ["canvas-export"] },
  "texture:inference": {
    label: "Texture",
    value: [
      "inference-txt2img-texture",
      "inference-img2img-texture",
      "inference-controlnet-texture",
      "inference-reference-texture",
    ],
  },
  "texture:textureSet": {
    label: "Texture Set",
    value: ["texture"],
  },
  "texture:textureMaps": {
    label: "Texture Map",
    value: [
      "texture-albedo",
      "texture-ao",
      "texture-edge",
      "texture-height",
      "texture-metallic",
      "texture-normal",
      "texture-smoothness",
    ],
  },
  "texture:upscaled": {
    label: "Enhanced Texture",
    value: ["upscale-texture"],
  },
  "skybox:inference": {
    label: "Preview",
    value: ["skybox-base-360"],
  },
  "skybox:upscaled": {
    label: "Enhanced Skybox",
    value: ["upscale-skybox"],
  },
};
